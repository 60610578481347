import React, { useEffect, useState } from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import { Link, useLocation } from 'react-router-dom';
import { Spinner, OverlayTrigger, Tooltip } from 'react-bootstrap';
import PaginationComponent from '../../Shared/Pagination';
import EmptyState from '../../Shared/EmptyState';
import { Navbar } from '../../../components/Navbar';
import { LogOutModal } from '../../../components/LogOutModal';

import api from '../../../config/configApi';

export function Users() {
  const { state } = useLocation();

  const [data, setData] = useState([]);
  const [paginationData, setPaginationData] = useState();

  const [page, setPage] = useState(1);
  const [lastPage, setLastPage] = useState(1);
  const [spinnerSearch, setSpinnerSearch] = useState(false);
  const [searchName, setSearchName] = useState('');
  const [isActive, setIsActive] = useState('true');

  const [status, setStatus] = useState({
    type: state ? state.type : '',
    mensagem: state ? state.mensagem : '',
  });

  const getUsers = async (page = 1) => {
    setSpinnerSearch(true);
    setPage(page);

    const headers = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    };

    const params = {
      page,
      nome: searchName,
    };

    if (isActive === 'true') {
      params.isActive = true;
    } else if (isActive === 'false') {
      params.isActive = false;
    }
    await api
      .get('/user', { ...headers, params })
      .then((response) => {
        setData(Array.isArray(response.data.result) ? response.data.result : []);
        setPaginationData({
          lastPage: response?.data?.lastPage,
          previousPage: response?.data?.previousPage,
          nextPage: response?.data?.nextPage,
          totalElements: response?.data?.totalElements,
          totalPerPage: response?.data?.totalPerPage,
          currentPage: response?.data?.currentPage,
        });
        setLastPage(response.data.lastPage);
      })
      .catch((err) => {
        if (err.response) {
          setStatus({
            type: 'error',
            mensagem: err.response.data.mensagem,
          });
        } else {
          setStatus({
            type: 'error',
            mensagem: 'Erro: Tente mais tarde!',
          });
        }
      });
    setSpinnerSearch(false);
  };

  const handlePageChange = async (page) => {
    setPage(page);
    await getUsers(page);
  };

  useEffect(() => {
    getUsers(page);
  }, [page]);

  return (
    <>
      <div id="wrapper">
        <div id="content-wrapper" className="d-flex flex-column">
          <div id="content">
            <Navbar />
            <div className="container-fluid">
              <div className="card shadow mb-4">
                <div className="card-header py-3">
                  <div className="row">
                    <div className="col-xl-10 col-md-6 mb-4">
                      <h6 className="m-0 font-weight-bold text-primary">
                        Lista de
                        {' '}
                        {paginationData?.totalElements || 0}
                        {' '}
                        Usuários
                      </h6>
                    </div>

                    <div className="col-xl-2 col-md-6 mb-4">
                      <Link to="/add-user">
                        <button type="button" className="btn btn-success">
                          Cadastrar
                        </button>
                      </Link>
                    </div>
                  </div>

                  <div className="row align-items-center">
                    <div className="col-lg-2 col-md-6 mb-3">
                      <Form.Label className="sr-only">Nome</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Digite o nome"
                        onChange={(e) => setSearchName(e.target.value)}
                      />
                    </div>
                    <div className="col-lg-2 col-md-6" style={{ marginTop: '-16px' }}>
                      <select
                        className="custom-select my-1 mr-sm-2"
                        name="status"
                        id="status"
                        onChange={(e) => setIsActive(e.target.value)}
                        defaultValue="true"
                      >
                        <option value="true">Ativos</option>
                        <option value="false">Inativos</option>
                        <option value="">Todos</option>
                      </select>
                    </div>
                    <div className="col-lg-4 col-md-6 mb-3">
                      <Button
                        variant="primary"
                        onClick={() => handlePageChange(1)} // Aciona a busca ao clicar no botão
                        className="ml-2"
                      >
                        <span>
                          <FontAwesomeIcon icon={faMagnifyingGlass} />
                        </span>
                    &nbsp;Localizar
                      </Button>
                    </div>
                  </div>
                  <div className="alert-content-adm">
                    {status.type === 'error' ? (
                      <p className="alert-danger">{status.mensagem}</p>
                    ) : (
                      ''
                    )}
                    {status.type === 'success' ? (
                      <p className="alert-success">{status.mensagem}</p>
                    ) : (
                      ''
                    )}
                  </div>
                </div>
                <div className="table-responsive">
                  <div className="card-body">
                    {spinnerSearch ? (
                      <div className="d-flex justify-content-center">
                        <Spinner
                          as="span"
                          animation="border"
                          size="lg"
                          role="status"
                          aria-hidden="true"
                        >
                          <span className="sr-only">Buscando...</span>
                        </Spinner>
                      </div>
                    ) : (
                      <>
                        <table className="table table-sm">
                          <thead>
                            <tr>
                              <th hidden>ID</th>
                              <th>Nome</th>
                              <th>E-mail</th>
                              <th>Whatsapp</th>
                              <th>Status</th>
                              <th>Ações</th>
                            </tr>
                          </thead>
                          <tbody>
                            {data.map((user) => (
                              <tr key={user.id}>
                                <td hidden>{user.id}</td>
                                <td>
                                  <OverlayTrigger
                                    placement="top"
                                    overlay={(
                                      <Tooltip>
                                        {user.allFieldsAreFilled
                                          ? 'Perfil completo'
                                          : user.unfilledTexts.map((text, index) => (
                                            <React.Fragment key={index}>
                                              {text}
                                              {index < user.unfilledTexts.length - 1 && <br />}
                                            </React.Fragment>
                                          ))}
                                      </Tooltip>
                                  )}
                                  >
                                    <span>
                                      {user.allFieldsAreFilled ? (
                                        <i className="text-success fas fa-check-circle fa-lg me-2" />
                                      ) : (
                                        <i className="text-danger fas fa-times-circle fa-lg me-2" />
                                      )}
                                    </span>
                                  </OverlayTrigger>
                                  <span>{user.nome}</span>
                                </td>

                                <td>{user.email}</td>
                                <td>
                                  {' '}
                                  {user.celular != '' ? (
                                    <a
                                      href={`https://web.whatsapp.com/send/?phone=55${user.celular}`}
                                      target="_blank"
                                      rel="noreferrer"
                                    >
                                      {user.celular}
                                    </a>
                                  ) : (
                                    'Não Informado'
                                  )}
                                </td>
                                <td>
                                  {user.isActive ? (
                                    <span className="badge badge-success">ATIVO</span>
                                  ) : (
                                    <span className="badge badge-secondary">INATIVO</span>
                                  )}
                                </td>
                                <td>
                                  <div className="row">
                                    <div className="col-xl-6 col-md-6 mb-4">
                                      <div className="input-group-append">
                                        <Link to={`/view-user/${user.id}`}>
                                          <button className="btn btn-secondary" type="button">
                                            <i className="fas fa-eye fa-sm" />
                                          </button>
                                        </Link>
                                      </div>
                                    </div>

                                    <div className="col-xl-6 col-md-6 mb-4">
                                      <div className="input-group-append">
                                        <Link to={`/edit-user/${user.id}`}>
                                          <button className="btn btn-primary" type="button">
                                            <i className="fas fa-edit fa-sm" />
                                          </button>
                                        </Link>
                                      </div>
                                    </div>
                                  </div>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                        <EmptyState isActive={!data || data.length === 0} />
                      </>
                    )}
                    {!!data.length && (
                      <PaginationComponent
                        paginationData={paginationData}
                        handlePageChange={handlePageChange}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <LogOutModal />
    </>
  );
}
